module.exports = [{
      plugin: require('../node_modules/@outdoormap/gatsby-plugin-brand-sites/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://api.naturkartan.se","apiKey":"82e393c9-072f-4a2e-a018-63024493832a","guideId":"251","siteUrl":"https://www.tiveden.se","lang":"sv","typesCategories":{"packages":["117"],"stays":["55","56","123","122","89","81"],"eatDrink":["54","106"],"biking":["31","120","8","16","74"],"hiking":["33","109","116","7","35"],"fishing":["26","118","105","115"],"riding":["34"],"canoeing":["37","80"],"more":[]},"typesCategoriesIncluded":{"packages":["117"],"stays":["55","56","123","122","89","81"],"eatDrink":["54","106"],"biking":["31","120","8","16","74","55","56"],"hiking":["33","109","116","7","55","56","35"],"fishing":["26","118","105","115","55","56"],"riding":["34","55","56"],"canoeing":["37","80","55","56"],"more":[]},"typesSitesSort":{"stays":{"popularity":"DESC"},"eatDrink":{"popularity":"DESC"}},"imagePlaceholderColor":"#949d61","templates":{"type":"/opt/build/repo/src/templates/type.js","site":"/opt/build/repo/src/templates/site.js","page":"/opt/build/repo/src/templates/page.js","articles":"/opt/build/repo/src/templates/articles.js","articlesPost":"/opt/build/repo/src/templates/articles-post.js","search":"/opt/build/repo/src/templates/search.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"sv","name":"Tiveden","short_name":"Tiveden","description":"Upptäck Sveriges sydligaste vildmark!","theme_color":"#29292b","background_color":"#fff","icon":"static/meta-images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
